<template>
  <div class="page">
    <Navbar isBack="true" title="充值记录"/>
    <div class="header">
      <Tabbar :tab='tab' @change="tabChange" />
    </div>
    <div class="page_list" :style="`width: 200%; transform: translateX(${25 - tabIndex * 50}%);`">
      <div class="list">
        <div
          v-for="(item, index) in list"
          :key='index'
          class="list_item">
          <div class="list_item_name">金额：{{item.val}}</div>
          <div class="list_item_text">订单号：{{item.order}}</div>
          <div class="list_item_text">时间：{{item.date}}</div>
          <div class="list_item_line"></div>
          <div class="list_item_option">
            <div class="list_item_option_text">充值状态：</div>
            <div class="list_item_btn" :style="`color: ${getItemBtnColor(item.state)}`">{{getItemBtnText(item.state)}}</div>
          </div>
        </div>
        <NoData v-if="list.length > 0&&listNoMore" title="暂无更多记录~" />
        <NoData v-if="list.length == 0" title="暂无记录~" />
      </div>
      <div class="list">
        <div
          v-for="(item, index) in list2"
          :key='index'
          class="list_item">
          <div class="list_item_name">金额：{{item.val}}</div>
          <div class="list_item_text">订单号：{{item.order}}</div>
          <div class="list_item_text">时间：{{item.date}}</div>
        </div>
        <NoData v-if="list2.length > 0&&listNoMore2" title="暂无更多记录~" />
        <NoData v-if="list2.length == 0" title="暂无记录~" />
      </div>
    </div>
  </div>
</template>
<script>
import Tabbar from '@/components/tabbar.vue'
export default {
  components: {
    Tabbar
  },
  data(){
    return {
      tabIndex: 0,
      tab: [
        {name: '充值记录', check: true},
        {name: '平台币记录', check: false}
      ],
      list: [ // 可领取
        // state 1 成功 2 失败
        {val: '10000', state: 1, date: '2023-01-02 15:20:45', order: 1512151125454122},
        {val: '10000', state: 1, date: '2023-01-02 15:20:45', order: 1512151125454122},
        {val: '10000', state: 2, date: '2023-01-02 15:26:45', order: 1512151125454122},
      ],
      listNoMore: true, // 是否还有更多
      list2: [ // 可使用
        {val: '10000', state: 2, desc: '活动1', date: '2023-01-02 15:26:45', order: 1512151125454122},
      ],
      listNoMore2: true, // 是否还有更多
    }
  },
  mounted () {
  },
  methods: {
    tabChange (e) {
      this.tabIndex = e
    },
    getItemBtnText (key) {
      let arr = ['成功', '失败']
      return arr[key - 1]
    },
    getItemBtnColor (key) {
      let arr = ['#07c344', '#ff0000']
      return arr[key - 1]
    },
  }
}
</script>
<style scoped lang='scss'>
.page{
  background-color: #f0f0f0;
}
.header{
  width: 100%;
  background-color: #fff;
}
.page_list{
  display: flex;
  overflow: hidden;
  transition: .3s;
  flex: 1;
}
.list{
  padding: .3rem .2rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.list_item{
  margin-bottom: .15rem;
  padding: .2rem .15rem;
  width: 100%;
  background-color: #fff;
  border-radius: .15rem;
  box-sizing: border-box;
}
.list_item_name{
  padding: .2rem 0 .1rem 0;
  font-weight: bold;
  color: $color-primary;
}
.list_item_text{
  padding: .06rem 0;
  font-size: .24rem;
  color: $color-primary;
}
.list_item_line{
  margin: .2rem 0;
  flex: 1;
  height: 1px;
  background: linear-gradient(to right, #ddd, #ddd 5px, transparent 5px, transparent);
  background-size: 15px 100%;
}
.list_item_option{
  display: flex;
  align-items: center;
  font-size: .25rem;
}

.list_item_option_text{
  flex: 1;
  color: #8d8d8d;
}
.list_item_btn{
}
</style>